<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              v-if="false"
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              PesaSave
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="d-flex text-xl font-weight-semibold text--primary mb-2 center align-center justify-center">
            Forgot Password?
          </p>
          <p v-if="!codeSent" class="mb-2">
            Please enter your email address and we will send you a six digit code to reset your password
          </p>
          <p v-else>
            We have sent you an email with a six digit code. please enter the code below to reset your password.
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <template v-for="error in form.errors">
            <v-alert :key="error" :value="true" type="error" class="mb-3">
              <small> {{ error[0] }}</small>
            </v-alert>
          </template>
          <v-form>
            <div v-if="!codeSent">
              <v-text-field
                v-model="form.username"
                outlined
                label="Email"
                placeholder="john@example.com"
                hide-details
                class="mb-3"
              ></v-text-field>
              <v-btn
                block
                :disabled="!form.username"
                color="primary"
                class="mt-6"
                :loading="loading"
                @click="sendToken"
              >
                Send Code
              </v-btn>
            </div>
            <div v-if="codeSent">
              <v-otp-input outlined label="Code" v-model="form.token" length="6"></v-otp-input>
              <v-text-field
                v-model="form.password"
                hint="atleast 6 characters"
                :rules="[rules.required, rules.min]"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="New password"
                placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
              <v-text-field
                v-model="form.password_confirmation"
                :rules="[rules.required, rules.min]"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Confirm password"
                placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>

              <v-btn block color="primary" class="mt-6" :loading="loading" @click="resetPassword">
                Reset Password
              </v-btn>
            </div>
          </v-form>
        </v-card-text>

        <!-- divider -->
        <v-card-text v-if="false" class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  data() {
    return {
      isPasswordVisible: false,
      loading: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      codeSent: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 6 || 'Min 6 characters',
        emailMatch: () => "The email and password you entered don't match",
      },
      form: {
        username: '',
        token: '',
        password: '',
        password_confirmation: '',
        errors: {},
      },
    }
  },
  methods: {
    errors(res) {
      this.form.errors = res.data.errors
    },
    sendToken() {
      this.loading = true
      this.form.errors = null
      this.$http
        .post('/auth/forgot-password', {
          username: this.form.username,
        })
        .then(response => {
          console.log(response.data)
          this.codeSent = true
        })
        .catch(errors => {
          // this.errors = errors.response.data.errors
          console.error(errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetPassword() {
      this.loading = true
      this.form.errors = null
      this.$http
        .post('/auth/reset-password', this.form)
        .then(response => {
          console.log(response.data)
          this.$router.push('/login')
        })
        .catch(errors => {
          this.errors(errors.response)
          console.error(errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
